import React, { Component } from "react"

export default class PlansFeatures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActiveTab: this.props.plans.oceanPlanContent.name,
      colSpan: "",
    }
  }

  handleClick = (tabName) => {
    this.setState({ isActiveTab: tabName })
  }

  componentDidMount() {
    this.setState({ colSpan: window.innerWidth <= 767 ? 2 : 4 })
  }

  render() {
    const data = this.props

    const plansContent = [data.plans.beachPlanContent, data.plans.seaPlanContent, data.plans.oceanPlanContent]

    const planImages = this.props.planImages

    return (
      <div name={data.scrollTo} className="plans-wrapper position-relative u-pricing-v2-wrapper z-index-2">
        <div className="container u-space-4">
          {/* <!-- SubTitle --> */}
          <div className="w-md-80 w-lg-50 text-center mx-auto mb-9">
            <h2 className="jd-h2-titles">
              {" "}
              {data.firstSubtitle} <span className="font-weight-bold">{data.mainSubtitle}</span>
            </h2>
          </div>
          {/* <!-- End SubTitle --> */}
          <article>
            <div className="jd-table-planos-mobile">
              <ul className="price">
                {plansContent.map((plan, i) => {
                  return plan.isRecommended ? (
                    <li key={i} className={"bg-dark-blue" + (this.state.isActiveTab === plan.name ? " active" : "")}>
                      <button onClick={() => this.handleClick(plan.name)}>{plan.name}</button>
                    </li>
                  ) : (
                    <li key={i} className={"bg-blue" + (this.state.isActiveTab === plan.name ? " active" : "")}>
                      <button onClick={() => this.handleClick(plan.name)}>{plan.name}</button>
                    </li>
                  )
                })}
              </ul>

              <table className="table table-borderless" id="jd-compare-plans-table">
                <thead>
                  <tr>
                    <th className="hide" aria-label="Coluna da tabela de preços"></th>
                    {plansContent.map((plan, i) => {
                      return plan.isRecommended ? (
                        <th key={i} className="jd-table-td jd-tag default">
                          recomendado
                        </th>
                      ) : (
                        <th key={i} className="jd-table-td bg-blue" aria-label="Coluna do plano"></th>
                      )
                    })}
                  </tr>

                  <tr>
                    <th scope="col" aria-label="Coluna do plano"></th>
                    {plansContent.map((plan, i) => {
                      return (
                        <th key={i} scope="col" className="jd-table-th">
                          <img
                            className="img-fluid"
                            style={{ padding: "5px" }}
                            width="47%"
                            src={
                              plan.name === "Praia"
                                ? planImages[0].svg
                                : plan.name === "Mar"
                                ? planImages[1].svg
                                : planImages[2].svg
                            }
                            alt="Ícone do plano"
                          />
                          <br />
                          <span className="jd-plans-header">{plan.name}</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <br />
                    </td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {data.plans.currency}{" "}
                          {plan.isRecommended ? (
                            <span className=" jd-plans-prices" style={{ color: "#2b3e8d" }}>
                              {plan.price}
                            </span>
                          ) : (
                            <span className="jd-plans-prices">{plan.price}</span>
                          )}{" "}
                          /mês
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td>
                      <br />
                    </td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          <a
                            className="btn jd-btn-primary--air u-btn-wide--sm rounded-pill vrlps-trigger"
                            href={plan.ourPlansLink}
                          >
                            {plan.ourPlansText}
                          </a>
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Design gráfico ilimitado</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.unlimitedGraphicDesign ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Web design ilimitado</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.unlimitedWebDesign ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Revisões ilimitadas</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.revisions ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Marcas</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.brands}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Usuários</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.users}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Todos os direitos inclusos</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.rightsIncluded ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Entrega</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.turnaround_short}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Designer dedicado</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.dedicatedDesigner ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Web designer dedicado</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.dedicatedWebDesigner ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td>Gerente de conta dedicado</td>
                    {plansContent.map((plan, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === plan.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {plan.dedicatedAccountManager ? "Sim" : "-"}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Negócios e Publicidade
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Banner e cartaz</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.banner ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Crachá de identificação</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.identificationBadge ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Display para exposição</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.display ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Edição simples de foto</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.simplePhotoEditing ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Folder e pasta</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.folder ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Gift card</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.giftCard ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Livreto — brochura</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.booklet ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Menu de restaurante</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.restaurantMenu ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Panfleto e folheto</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.pamphletBrochure ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Poster</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.poster ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Sinalização</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.signalling ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Comunicação interna</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.internalCommunication ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ilustração simples</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.simpleIllustration ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Modelo Apple Keynote</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.keynoteTemplate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Modelo Microsoft PowerPoint</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.powerpointTemplate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Modelo Microsoft Word</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.wordTemplate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Design de relatório</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.reportDesign ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Catálogo</td>
                    {data.bussinessAdvertising.map((business, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === business.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {business.catalog ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Papelaria
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Adesivo e etiqueta</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.adhesiveLabel ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Carimbo</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.stamp ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Cartão de mensagem</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.giftCardPresent ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Cartão postal</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.postCard ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Cartão de visita</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.businessCard ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Convite</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.invitation ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Envelope</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.envelope ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Papel timbrado</td>
                    {data.stationarysystem.map((system, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === system.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {system.letterhead ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Web-design &amp; Mídia
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Banner para website</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.webBanner ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa para Facebook</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.facebookCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa para Linkedin</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.linkedinCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa para Twitter</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.twitterCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa para Youtube</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.youtubeCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Imagem para blog posts</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.postBlog ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Imagem para post em redes sociais</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.postSocialNetworks ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Formulário</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.form ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Design de ícone</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.iconDesign ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Design de botão</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.buttonDesign ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ícone de aplicativo</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.appsIcon ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Design de landing page</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.landingPage ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ícones para website</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.iconsWebsite ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Layout para email marketing</td>
                    {data.webDesignMedia.map((web, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === web.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {web.emailMarketing ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Itens Promocionais
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Avental</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.apron ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Boné</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.cap ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Camiseta</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.tShirt ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Copo e caneca</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.cupMug ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Mochila e sacola</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.BackpackBag ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Moleton</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.pulloverShirt ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Uniforme</td>
                    {data.promotionalClothing.map((promote, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === promote.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {promote.uniform ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Logo &amp; Identidade
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Estampa</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.print ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Logo simples</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.simpleLogo ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Guia de estilos</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.styleGuide ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Identidade visual</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.visualIdentity ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Logotipo e marca</td>
                    {data.logoIdentity.map((logo, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === logo.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {logo.logoBrand ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Rótulo &amp; Embalagens
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Embalagem padrão</td>
                    {data.labelPackaging.map((label, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === label.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {label.standardPacking ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Rótulo padrão</td>
                    {data.labelPackaging.map((label, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === label.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {label.standardLabel ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Livros &amp; Revistas
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa de livro</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.bookCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa de revista</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.magazineCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Capa para e-book</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.ebookCover ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Infográfico simples</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.simpleInfographic ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Infográfico complexo</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.complexInfographic ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Diagramação de livro</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.bookInterior ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Diagramação de revista</td>
                    {data.booksMagazines.map((book, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === book.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {book.magazineInterior ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <td className="sep" colSpan={this.state.colSpan}>
                      Arte &amp; Ilustrações
                    </td>
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ilustração para convite</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.invitation ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ilustração para website</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.website ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Edição complexa de foto</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.photoEdit ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ilustração corporativa</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.corporate ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ilustração de livro</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.book ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                  <tr className="jd-plans-th-line jd-table-th-produtos">
                    <td className="jd-table-th-category">Ilustração de revista</td>
                    {data.artIllustrations.map((art, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            "jd-table-td" + (this.state.isActiveTab === art.name ? " activeCell" : " unactiveCell")
                          }
                        >
                          {art.magazine ? "✔" : ""}
                        </td>
                      )
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </article>
        </div>
      </div>
    )
  }
}
